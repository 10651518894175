import { Component, Ref, Vue, Watch } from 'vue-property-decorator'

import store from '@/app/store'
import PaginationRequest from '@/common/components/pagination/pagination-request'
import tenantsService from '@/tenants/services/tenants-service'
import UserDialog from '@/users/components/user-dialog/user-dialog'
import User from '@/users/entities/user'
import accountService from '@/users/services/account-service'
import usersService from '@/users/services/users-service'
import AuthModule from '@/users/store/auth-module'
import CustomerCategory from '@/customers/entities/customer-category'
import { getModule } from 'vuex-module-decorators'
import customersService from '@/customers/services/customers-service'
import countriesService from '@/locations/services/countries-service'
import moment from 'moment'
import Tenant from '@/tenants/entities/tenant'
import Country from '@/locations/models/country'

@Component
export default class Settings extends Vue {

  @Ref()
  private userDialog!: UserDialog

  private tab: any = null

  private loading: boolean = false
  private users: User[] = []
  private tenant: Tenant = new Tenant()
  private countries: Country[] = []
  private file = new File([''], '')
  private logoUrl = '#'
  private isTenantLoading = false

  private snackbarText = 'Erfolgreich gespeichert.'
  private snackbarOpened = false

  private pagination = new PaginationRequest()

  private async mounted() {
    this.updateLogoUrl()
    await this.loadTenant()
    this.countries = countriesService.findAllAsync()
  }

  private async loadTenant() {
    this.isTenantLoading = true
    const authModule = getModule(AuthModule, store)
    let t = await tenantsService.findOneAsync(authModule.tenant)
    if (t) {
      this.tenant = t
    }
    this.isTenantLoading = false;
  }

  private async fetchAll() {
    this.loading = true
    const response = await usersService.findAllAsync(this.pagination)
    this.users = response.items
    this.pagination.totalItems = response.totalItems
    this.loading = false
  }

  private create() {
    this.userDialog.create()
  }

  private edit(user: User) {
    this.userDialog.edit(user)
  }

  private async save(user: User) {
    await accountService.createAsync(user)
    await this.fetchAll()
  }

  private async update(user: User) {
    await accountService.updateAsync(user)
    await this.fetchAll()
  }

  private async activate(user: User) {
    await accountService.activateAsync(user)
    await this.fetchAll()
  }

  private async fileSelected(file: File) {
    if (file.size > 0) {
      await tenantsService.uploadLogo(file)
      this.updateLogoUrl()
    }
  }

  private async removeLogo() {
    await tenantsService.removeLogo()
    const img = this.$refs['logoImageTag'] as HTMLImageElement
    img.style.display = 'none'
  }

  private updateLogoUrl() {
    const authModule = getModule(AuthModule, store)
    this.logoUrl = `${process.env.VUE_APP_API_URL}/tenants/logo?id=${authModule.tenant}&${new Date().getTime()}`
    const img = this.$refs['logoImageTag'] as HTMLImageElement
    if (img) {
      img.style.display = 'block'
    }
  }

  private async saveTenant() {
    this.isTenantLoading = true
    await tenantsService.updateCurrent(this.tenant)
    this.snackbarOpened = true
    this.isTenantLoading = false
  }

  @Watch('pagination', { deep: true })
  private async paginationChanged(newVal: PaginationRequest, oldVal: PaginationRequest) {
    if (newVal !== oldVal) {
      await this.fetchAll()
    }
  }
}
