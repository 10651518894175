import Guid from '@/app/utils/guid'
import Address from '@/locations/models/address'
import moment from 'moment'

export default class Tenant {

  public id: string = Guid.empty()
  public firstName: string = ''
  public lastName: string = ''
  public company: string = ''
  public vat: string = ''
  public email: string = ''
  public bank: string = ''
  public bic: string = ''
  public iban: string = ''
  public membershipNumber: string = ''
  public isSmallBusiness: boolean = false
  public contractRenewalDate: (Date|null) = null
  public hasAutoRenewalContract: boolean = false
  public shiftStartTime: (Date|null) = null
  public shiftEndTime: (Date|null) = null
  public address = new Address()
  public termsAccepted = false
  public privacyAccepted = false
  public evaluationStart: (Date|null) = null
}
