import PagedMixin from '@/app/mixins/paged-mixin'
import Tenant from '@/tenants/entities/tenant'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class TenantsTable extends Mixins(PagedMixin) {

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ default: () => [] })
  public tenants!: Tenant[]

  public options: any = {}

  get headers() {
    return [
      { text: 'Firma', value: 'company' },
      { text: 'Mitgliedsnr.', value: 'membershipNumber' },
      { text: 'Name', value: 'name' },
      { text: 'E-Mail', value: 'email' },
      { text: 'Letzte Vertragsverlängerung', value: 'contractRenewalDate' },
      { text: 'autom. Verlängerung', value: 'hasAutoRenewalContract' },
      { text: '', value: 'action', sortable: false, width: '1px' }
    ]
  }

  private edit(tenant: Tenant) {
    this.$emit('edit', tenant)
  }

  private impersonate(tenant: Tenant) {
    this.$emit('impersonate', tenant)
  }
}
