import Tenant from '@/tenants/entities/tenant'
import cloneDeep from 'lodash/cloneDeep'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import countriesService from '@/locations/services/countries-service'
import Country from '@/locations/models/country'
import moment from 'moment'

@Component
export default class TenantDialog extends Vue {

  private dialog = false
  private editMode = false
  private tenant = new Tenant()
  private countries: Country[] = []
  private contractRenewalDatePicker = false
  private contractRenewalDate = ''
  private evaluationStartPicker = false
  private evaluationStart = ''

  public create(tenant?: Tenant) {
    this.tenant = tenant || new Tenant()
    this.dialog = true
    this.editMode = false
    this.$validator.reset()
  }

  public edit(tenant: Tenant) {
    this.tenant = cloneDeep(tenant)
    this.dialog = true
    this.editMode = true
    this.$validator.reset()
    this.contractRenewalDate = this.tenant.contractRenewalDate
      ? moment(this.tenant.contractRenewalDate as Date).format('YYYY-MM-DD')
      : ''
    this.evaluationStart = this.tenant.evaluationStart
      ? moment(this.tenant.evaluationStart as Date).format('YYYY-MM-DD')
      : ''
  }

  public async save() {
    if (await this.$validator.validateAll()) {
      this.tenant.contractRenewalDate = new Date(this.contractRenewalDate)
      if (this.evaluationStart) {
        this.tenant.evaluationStart = new Date(this.evaluationStart)
      }
      this.$emit(this.editMode ? 'update' : 'save', this.tenant)
    }
  }

  public cancel() {
    this.$emit('cancel')
    this.dialog = false
  }

  public close() {
    this.dialog = false
  }

  mounted() {
    this.countries = countriesService.findAllAsync()
  }
}
