import Tenant from '@/tenants/entities/tenant'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TenantForm extends Vue {

  @Prop({ required: true })
  public value!: Tenant

  public valid: boolean = false
}
