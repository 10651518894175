


















































































































































































import { Component, Prop, Vue } from "vue-property-decorator"
import Tenant from "@/tenants/entities/tenant"
import Country from "@/locations/models/country"
import countriesService from "@/locations/services/countries-service"
import tenantsService from "@/tenants/services/tenants-service"
import router from "@/app/router"

@Component
export default class Signup extends Vue {
  evaluation = false
  valid = false
  isSending = false
  isSent = false
  tenant = new Tenant()
  countries: Country[] = []

  async send() {
    await this.$validator.validateAll()
    if (!this.$validator.errors.any()) {
      this.isSending = true
      await tenantsService.signupAsync(this.tenant)
      this.isSending = false
      this.isSent = true
    }
  }

  get termsAndPrivacy() {
    return this.tenant.termsAccepted && this.tenant.privacyAccepted
  }

  mounted() {
    this.countries = countriesService.findAllAsync()
    this.evaluation = router.currentRoute.params.evaluation === "eval"
    if (this.evaluation) {
      this.tenant.evaluationStart = new Date()
    }
  }
}
