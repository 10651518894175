import authService from '@/users/services/auth-service'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class UserWidget extends Vue {

  private menu: boolean = false

  get displayName() {
    return authService.displayName()
  }

  public async logout() {
    this.menu = false
    await authService.logoutAsync()
  }
}
