var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.users,"options":_vm.pPagination,"server-items-length":_vm.pPagination.totalItems,"footer-props":{ itemsPerPageText: 'Zeilen:' }},on:{"update:options":function($event){_vm.pPagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('address-line',{attrs:{"address":item.address}})]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('role-badge',{attrs:{"role":item.role}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Bearbeiten"},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("edit")]),_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Mit diesem Benutzer anmelden"},on:{"click":function($event){return _vm.impersonate(item)}}},[_vm._v("check")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }