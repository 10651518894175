import authService from '@/users/services/auth-service'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ResetPassword extends Vue {
    private email = ''
    private password = ''
    private passwordConfirmation = ''
    private serverError = ''
    private loggingIn = false

    @Prop(String)
    private token!: string

    public async resetPassword() {
        if (await this.$validator.validateAll()) {
            this.loggingIn = true
            const res = await authService.resetPasswordAsync(this.email, this.token, this.password)
            this.loggingIn = false

            if (res[0]) {
                this.$router.push('/login')
            } else {
                this.serverError = res[1]
            }
        }
    }
}
