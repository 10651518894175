import User from '@/users/entities/user'
import cloneDeep from 'lodash/cloneDeep'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class UserDialog extends Vue {

  private dialog: boolean = false
  private editMode: boolean = false
  private user: User = new User()

  get roles() {
    const rs: Array<{ value: string, text: string }> = []
    if (this.$auth.isAdmin) {
      rs.push({ value: 'ADMIN', text: 'Admin' })
    }
    rs.push({ value: 'TENANT', text: 'Manager*in' })
    rs.push({ value: 'DRIVER', text: 'Fahrer*in' })
    return rs
  }

  public create(user?: User) {
    this.user = user || new User()
    this.user.address.countryId = 'AT'
    this.dialog = true
    this.editMode = false
    this.$validator.reset()
  }

  public edit(user: User) {
    this.user = cloneDeep(user)
    this.dialog = true
    this.editMode = true
    this.$validator.reset()
  }

  public async save() {
    if (await this.$validator.validateAll()) {
      this.$emit(this.editMode ? 'update' : 'save', this.user)
      this.dialog = false
    }
  }

  public cancel() {
    this.$emit('cancel')
    this.dialog = false
  }

  private mounted() {
  }
}
