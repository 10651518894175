import axios from '@/app/axios'
import CrudService from '@/common/services/crud-service'
import Tenant from '@/tenants/entities/tenant'
import Responses from '@/app/utils/responses'
import PaginationRequest from '@/common/components/pagination/pagination-request'
import PaginationResponse from '@/common/components/pagination/pagination-response'

class TenantsService extends CrudService<Tenant> {
    public async updateIsSmallBusiness(id: string, value: boolean) {
        const url = `${this.resource}/updateIsSmallBusiness`
        await axios.put(url, value)
    }

    public async updateHasAutoRenewalContract(value: boolean) {
        const url = `${this.resource}/updateHasAutoRenewalContract`
        await axios.put(url, value)
    }

    public async updateShift(id: string, start: Date, end: Date) {
        const url = `${this.resource}/updateShift`
        await axios.put(url, { start: start, end: end })
    }

    public async uploadLogo(file: File) {
        const url = `${this.resource}/uploadLogo`
        const formData = new FormData()
        formData.append('file', file, file.name)
        await axios.post(url, formData)
    }

    public async removeLogo() {
        const url = `${this.resource}/removeLogo`
        await axios.post(url)
    }

    public async getCurrent() {
        const url = `${this.resource}/current`
        const res = await axios.get<Tenant>(url)
        return Responses.obj(res)
    }

    public async updateCurrent(model: Tenant) {
        const res = await axios.put<Tenant>(`${this.resource}/current`, model)
        return Responses.obj(res)
    }

    public async filterAllAsync(text: string, paging: PaginationRequest) {
        const res = await axios.post<PaginationResponse<Tenant>>(this.resource, {
            text: text,
            paging: paging,
        })
        return Responses.pagedList(res)
    }

    public async signupAsync(model: Tenant) {
        const res = await axios.post<Tenant>(`${this.resource}/signup`, model)
        return Responses.obj(res)
    }
}

export default new TenantsService('tenants')
