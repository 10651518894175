import PagedMixin from '@/app/mixins/paged-mixin'
import User from '@/users/entities/user'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class UsersTable extends Mixins(PagedMixin) {

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ default: () => [] })
  public users!: User[]

  get headers() {
    return [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'E-Mail', value: 'email' },
      { text: 'Adresse', value: 'address', sortable: false },
      { text: 'Rolle', value: 'role' },
      { text: '', value: 'action', sortable: false, width: '1px' },
    ]
  }

  private edit(user: User) {
    this.$emit('edit', user)
  }

  private impersonate(user: User) {
    this.$emit('impersonate', user)
  }
}
