import authService from '@/users/services/auth-service'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Login extends Vue {
    private showResetForm = false
    private email = ''
    private loading = false

    get isDemo() {
        return process.env.VUE_APP_DEMO === 'true'
    }

    public async sendResetLink() {
        this.loading = true
        await authService.sendPasswordResetLinkAsync(this.email)
        this.loading = false
        this.showResetForm = false
    }
}
