import { Component, Ref, Vue, Watch } from 'vue-property-decorator'

import PaginationRequest from '@/common/components/pagination/pagination-request'
import UserDialog from '@/users/components/user-dialog/user-dialog'
import User from '@/users/entities/user'
import accountService from '@/users/services/account-service'
import usersService from '@/users/services/users-service'
import authService from '@/users/services/auth-service'

@Component 
export default class CompanyUser extends Vue {

    @Ref()
    private userDialog!: UserDialog

    private loading: boolean = false
    private users: User[] = []

    private pagination = new PaginationRequest()

    private snackbarText = 'Erfolgreich gespeichert.'
    private snackbarOpened = false
  

    private create() {
        this.userDialog.create()
      }
    
      private edit(user: User) {
        this.userDialog.edit(user)
      }
    
      private async save(user: User) {
        await accountService.createAsync(user)
        await this.fetchAll()
      }
    
      private async update(user: User) {
        await accountService.updateAsync(user)
        await this.fetchAll()
      }
    
      private async activate(user: User) {
        await accountService.activateAsync(user)
        await this.fetchAll()
      }

      private async impersonate(user: User) {
        await authService.impersonate({ email: user.email, password: '', rememberMe: false })
      }

      private async fetchAll() {
        this.loading = true
        const response = await usersService.findAllAsync(this.pagination)
        this.users = response.items
        this.pagination.totalItems = response.totalItems
        this.loading = false
      }

    private async mounted() {

    }

    @Watch('pagination', { deep: true })
    private async paginationChanged(newVal: PaginationRequest, oldVal: PaginationRequest) {
      if (newVal !== oldVal) {
        await this.fetchAll()
      }
    }
}