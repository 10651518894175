import Ride from '@/rides/models/ride'
import Guid from '@/app/utils/guid'

export default class Rides {

  public static toRegularRide(ride: Ride) {
    // Wir wandeln das Template in einen Auftrag um.
    ride.isTemplate = false
    // Die ID des Templates kopieren wir in das Feld recurringRideId.
    ride.recurringRideId = ride.id
    // Dann löschen wir das id Feld.
    ride.id = Guid.empty()

    return ride
  }
}