import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import CrudService from '@/common/services/crud-service'
import User from '@/users/entities/user'
import RegistrationRequest from '@/users/requests/registration-request'
import RegistrationResponse from '@/users/responses/registration-response'

class AccountService extends CrudService<User> {

  public async registerAsync(request: RegistrationRequest) {
    // const res = await axios.post<RegistrationResponse>('account/register', request)
    // return Responses.obj(res)
    return null
  }

  public async activateAsync(user: User) {
    await axios.post(`account/${user.id}/activate`)
  }

  public async enableAsync(user: User) {
    await axios.post(`account/${user.id}/enable`)
  }

  public async disableAsync(user: User) {
    await axios.post(`account/${user.id}/disable`)
  }

  public async getCurrent() {
    const url = `${this.resource}/current`
    const res = await axios.get<User>(url)
    return Responses.obj(res)
  }

  public async updateCurrent(model: User) {
    const res = await axios.put<User>(`${this.resource}/updateCurrent`, model)
    return Responses.obj(res)
  }
}

export default new AccountService('account')
