import AuthRequest from '@/users/requests/auth-request'
import AuthResponse from '@/users/responses/auth-response'
import authService from '@/users/services/auth-service'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LoginForm extends Vue {

  private loggingIn = false
  private request = new AuthRequest()
  private response: AuthResponse | null = new AuthResponse()

  public async login() {
    if (await this.$validator.validateAll()) {
      this.loggingIn = true
      this.response = await authService.loginAsync(this.request)
      this.loggingIn = false
    }
  }
}
