import Permissions from '@/users/permissions'
import Vue from 'vue'
import { PluginObject, VueConstructor } from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $auth: Permissions
  }
}

class VuePermissionsPlugin implements PluginObject<any> {

  public install(vue: VueConstructor, options?: any): void {
    vue.prototype.$auth = new Permissions()
  }
}

Vue.use(new VuePermissionsPlugin())
