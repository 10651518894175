import { Component, Ref, Vue, Watch } from 'vue-property-decorator'

import PaginationRequest from '@/common/components/pagination/pagination-request'
import TenantDialog from '@/tenants/components/tenant-dialog/tenant-dialog'
import Tenant from '@/tenants/entities/tenant'
import tenantsService from '@/tenants/services/tenants-service'
import authService from '@/users/services/auth-service'

@Component
export default class Tenants extends Vue {

  @Ref()
  private dialog!: TenantDialog

  private loading: boolean = false
  private tenants: Tenant[] = []

  private text: string = ''

  private pagination = new PaginationRequest()

  private async clearAndFetchAll() {
    this.text = ''
    await this.fetchAll()
  }

  public async fetchAll() {
    this.loading = true
    const response = await tenantsService.filterAllAsync(this.text, this.pagination)
    this.tenants = response.items
    this.pagination.totalItems = response.totalItems
    this.loading = false
  }

  public create() {
    this.dialog.create()
  }

  public edit(tenant: Tenant) {
    this.dialog.edit(tenant)
  }

  public async impersonate(tenant: Tenant) {
    await authService.impersonate({ email: tenant.email, password: '', rememberMe: false })
  }

  public async save(tenant: Tenant) {
    if (await tenantsService.createAsync(tenant)) {
      await this.fetchAll()
      this.dialog.close()
    }
  }

  public async update(tenant: Tenant) {
    if (await tenantsService.updateAsync(tenant)) {
      await this.fetchAll()
      this.dialog.close()
    }
  }

  @Watch('pagination', { deep: true })
  private async paginationChanged(newVal: PaginationRequest, oldVal: PaginationRequest) {
    if (newVal !== oldVal) {
      await this.fetchAll()
    }
  }
}
