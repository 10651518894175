import PaginationRequest from "@/common/components/pagination/pagination-request";
import Period from "@/common/components/periods/period";
import XConfirmDialog from "@/common/components/x-confirm-dialog/x-confirm-dialog";
import ItinerariesMixin from "@/itineraries/mixins/itineraries-mixin";
import RidesDialog from "@/rides/components/rides-dialog.vue";
import Ride from "@/rides/models/ride";
import RidesFilter from "@/rides/models/rides-filter";
import ridesCalendarService from "@/rides/services/rides-calendar-service";
import ridesService from "@/rides/services/rides-service";
import moment, { Moment } from "moment";
import {
  Component,
  Mixins,
  Prop,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import { RidesOrder } from "../models/rides-order";
import snacks from "@/app/services/snacks";

@Component
export default class Rides extends Mixins(ItinerariesMixin) {
  @Ref()
  private confirmDialog!: XConfirmDialog;

  @Ref()
  private dialog!: RidesDialog;

  private text: string = "";
  private showDate: boolean = true;
  private rides: Ride[] = [];
  private agenda: Ride[] = [];
  private loading: boolean = false;

  private filter = new RidesFilter();
  private pagination = new PaginationRequest();

  @Watch("text")
  private textChanged(val: string) {
    this.filter.text = val;
    if (this.filter.text) {
      this.showDate = false;
    } else {
      this.showDate = true;
      this.fetchAll();
    }
  }

  private async fetchAll() {
    this.fetchTable();
    this.fetchAgenda();
  }

  private async reloadList() {
    this.dialog.cancel();
    this.fetchTable();
    snacks.success(`Die Rechnung wurde erfolgreich erzeugt.`);
  }

  private async fetchTable() {
    this.loading = true;
    const response = await ridesCalendarService.findAllPagedAsync(
      this.filter,
      this.pagination
    );

    this.rides = response.items;
    this.pagination.totalItems = response.totalItems;
    this.loading = false;
  }

  private async fetchAgenda() {
    this.loading = true;
    this.agenda = await ridesCalendarService.findAllAsync(this.filter);
    this.loading = false;
  }

  private create() {
    this.dialog.create();
  }

  private edit(ride: Ride) {
    this.dialog.edit(ride);
  }

  private async save(ride: Ride) {
    await ridesService.createAsync(ride);
    await this.fetchAll();
  }

  private async update(ride: Ride) {
    await ridesService.updateAsync(ride);
    await this.fetchAll();
  }

  private async toggleDriverFilter() {
    if (this.$auth.isDriver) {
      await this.fetchAll();
    }
  }

  private async remove(ride: Ride) {
    this.confirmDialog.open(ride);
  }

  private async removeConfirmed(ride: Ride) {
    await ridesService.deleteAsync(ride.id);
    await this.fetchAll();
  }

  @Watch("pagination", { deep: true })
  private paginationChanged(
    newVal: PaginationRequest,
    oldVal: PaginationRequest
  ) {
    if (newVal !== oldVal) {
      this.fetchTable();
    }
  }

  private created() {
    this.pagination.sortBy = ["date"];
    this.pagination.sortDesc = [true];

    this.filter.driversRidesOnly = this.$auth.isDriver;
    this.filter.period = new Period();
    this.filter.order = RidesOrder.DateAsc;
    this.filter.period.start = moment().format("YYYY-MM-DD");
    this.filter.period.end = moment()
      .add(4, "days")
      .format("YYYY-MM-DD");

    this.fetchAgenda();
  }
}
