import RegistrationRequest from '@/users/requests/registration-request'
import accountService from '@/users/services/account-service'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class RegisterForm extends Vue {

  private valid = true
  private request = new RegistrationRequest()

  private roles = [
    { name: 'Admin', value: 'ADMIN' },
    { name: 'Tenant-Admin', value: 'TENANT' },
    { name: 'Fahrer*in', value: 'DRIVER' },
  ]

  public async register() {
    const response = await accountService.registerAsync(this.request)
  }
}
