import Guid from '@/app/utils/guid'
import Address from '@/locations/models/address'

export default class User {

  public id = Guid.empty()
  public userName = ''
  public email = ''
  public tenantId = Guid.empty()
  public role = ''
  public firstName = ''
  public lastName = ''
  public address = new Address()
  public isDeleted = false
  public remarks = ''

  public get isNew() {
    return this.id == Guid.empty()
  }
}
